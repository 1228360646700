/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, NullValidationHandler, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ENV_CONFIG, EnvConfig } from '../../configuration';
import { Disposable } from '../shared/extensions/disposable';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { AppConstants } from '../shared/constants';
import { GetUserResponse, UpdateUserFromTokenRequest, UserClient } from '../shared/apis/api.client';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Disposable {
  private userSubject = new BehaviorSubject<User>(null);
  private isDoneLoadingSubject = new BehaviorSubject<boolean>(false);
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private muleSoftErrorSubject = new BehaviorSubject<boolean>(false);

  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  public isDoneLoading$ = this.isDoneLoadingSubject.asObservable();
  public user$ = this.userSubject.asObservable();
  public hasMuleSoftErrorSubject$ = this.muleSoftErrorSubject.asObservable();

  get hasMuleSoftErrorSubject(): boolean {
    return this.muleSoftErrorSubject.value;
  }

  get currentUser(): User {
    return this.userSubject.value;
  }

  constructor(
    private oauthService: OAuthService,
    private injector: Injector,
    private readonly sessionStorageService: SessionStorageService,
    @Inject(ENV_CONFIG) private readonly envConfig: EnvConfig,
    private userClient: UserClient
  ) {
    super();
    this._listenForErrors();
    this._listenForStorageChanges();
    this._listenForEvents();
    this._configureCodeFlow();
  }

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(
    map((values) => {
      return values.every((b) => b);
    })
  );

  public async runInitialLoginSequence(): Promise<void> {
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new NullValidationHandler();

    this.oauthService.events
      .pipe(filter((event) => event.type === 'token_refresh_error'))
      .subscribe((err) => this.retryTokenRefresh(err));

    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(
        location.hash
          .substr(1)
          .split('&')
          .map((kvp) => kvp.split('='))
      );
    }

    try {
      const success = await this.oauthService.loadDiscoveryDocumentAndTryLogin();

      if (success === false) {
        this.userSubject.next(null);
        return;
      }

      const identityClaims = this.oauthService.getIdentityClaims();

      if (identityClaims) {
        let rolePermissions = await this.userClient
          .getCurrentUserRolePermissions()
          .toPromise()
          .catch((error) => {
            console.error(error);
            this.oauthService.logOut();
          });

        rolePermissions = rolePermissions as GetUserResponse;
        identityClaims['role'] = rolePermissions?.role;
        identityClaims['permissions'] = rolePermissions?.permissions;
        identityClaims['isActive'] = rolePermissions?.isActive;
        this.muleSoftErrorSubject.next(rolePermissions.mulesoftError);
      }

      const currentUser = this.createUser(identityClaims);
      this.userSubject.next(currentUser);
      this.isDoneLoadingSubject.next(true);

      if (currentUser && this.oauthService.state) {
        await this.oauthService.refreshToken();

        const router = this.injector.get(Router);
        await router.navigateByUrl(decodeURIComponent(this.oauthService.state));
      }
    } catch (err) {
      if (
        !err.type ||
        err.type !== 'code_error' ||
        !err.params ||
        !err.params.error ||
        err.params.error !== 'access_denied'
      ) {
        throw err;
      }

      this.userSubject.next(null);
      return;
    }
  }

  login(redirectUrl: string): void {
    if (this.sessionStorageService.exists(AppConstants.LastUrlKeyName)) {
      redirectUrl = this.sessionStorageService.get(AppConstants.LastUrlKeyName);
      this.oauthService.initLoginFlow(redirectUrl);
      return;
    }
    // Check if we have any target if so use it now it is the truth.
    if (redirectUrl) {
      this.sessionStorageService.set(AppConstants.LastUrlKeyName, redirectUrl);
      this.oauthService.initLoginFlow(redirectUrl);
      return;
    }
    // Whelp now we know nothing so lets just use dashboard
    this.oauthService.initLoginFlow('/dashboard');
  }

  async logout(): Promise<void> {
    await this.oauthService.logOut();
  }

  public hasPermission(permission: string): boolean {
    if (!permission) {
      return true;
    }

    const authUser = this.userSubject.getValue();
    if (!authUser || !authUser.permissions || authUser.permissions.length === 0) {
      return false;
    }

    return authUser.permissions.includes(permission);
  }

  public hasAnyPermission(permissions: string[]): boolean {
    if (!permissions || permissions.length === 0) {
      return true;
    }

    const authUser = this.userSubject.getValue();

    if (!authUser || !authUser.permissions || authUser.permissions.length === 0) {
      return false;
    }

    return permissions.some((p) => authUser.permissions.includes(p));
  }

  private createUser(claims: any): User | null {
    if (!claims) {
      return null;
    }

    console.log(claims);

    return {
      firstName: claims.given_name,
      lastName: claims.family_name,
      fullName: `${claims.given_name} ${claims.family_name}`,
      wuPeopleId: claims.sub,
      email: claims.email,
      permissions: claims.permissions,
      role: claims.role,
      isActive: claims.isActive
    };
  }

  private retryTokenRefresh(err: any): void {
    if (!(err instanceof OAuthErrorEvent) || !(err.reason instanceof HttpErrorResponse) || err.reason.status !== 400) {
      setTimeout(() => this.oauthService.refreshToken(), 1000);
    }
  }

  private _listenForEvents(): void {
    this.oauthService.events.subscribe(() => {
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events.pipe(filter((e) => ['token_received'].includes(e.type))).subscribe(() => {
      const currentUser = this.createUser(this.oauthService.getIdentityClaims());
      if (currentUser && currentUser.wuPeopleId) {
        this.userClient
          .updateUserFromToken(
            new UpdateUserFromTokenRequest({
              email: currentUser.email,
              firstName: currentUser.firstName,
              lastName: currentUser.lastName
            })
          )
          .subscribe({
            next: () => console.log('User profile updated.'),
            error: (error) => {
              console.error('error updating user profile from token', error);
              window.location.href = '/noaccess';
            }
          });
      }
    });

    this.oauthService.events
      .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(() => {
        this.login('/signin-oidc');
      });
  }

  private _listenForStorageChanges(): void {
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.login('/signin-oidc');
      }
    });
  }

  private _listenForErrors(): void {
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });
    this.oauthService.events.pipe(filter((e: any) => e.type === 'invalid_nonce_in_state')).subscribe(() => {
      console.log('invalid_nonce_in_state');
      this.login('/signin-oidc');
    });
  }

  private _configureCodeFlow(): void {
    const config = this._baseConfig;
    config.responseType = 'code';
    this.oauthService.configure(config);
  }

  private get _baseConfig(): AuthConfig {
    return new AuthConfig({
      clientId: this.envConfig.authConfig.clientId,
      issuer: this.envConfig.authConfig.authority,
      redirectUri: window.origin + this.envConfig.authConfig.redirectUri,
      postLogoutRedirectUri: window.location.origin,
      responseType: 'code',
      scope: this.envConfig.authConfig.apiScope,
      showDebugInformation: this.envConfig.authConfig.showDebugInformation,
      requireHttps: this.envConfig.authConfig.requireHttps
    });
  }
}

export interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  wuPeopleId: number;
  email: string;
  permissions: string[];
  role: string;
  isActive: boolean;
}
