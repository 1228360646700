import { InjectionToken } from '@angular/core';

export interface AppInsightsConfig {
  instrumentationKey: string;
}

export interface AppConfig {
  apiBaseUrl: string;
  storageKeyPrefix: string;
}

export interface SecurityConfig {
  clientId: string;
  authority: string;
  redirectUri: string;
  apiResourceUri: string;
  apiScope: string;
  showDebugInformation: boolean;
  requireHttps: boolean;
}

export interface EnvConfig {
  sessionTimeout: number;
  siteName: string;
  appConfig: AppConfig;
  appInsights: AppInsightsConfig;
  authConfig: SecurityConfig;
  production: boolean;
  localTesting: boolean;
}

export const ENV_CONFIG = new InjectionToken<EnvConfig>('@@radarDashboardEnvironmentConfiguration');
